<template>
    <div>
        <v-row>
            <v-col cols="6" sm="2">
                <v-text-field
                    v-model="filters.defaultPrice"
                    suffix="฿"
                    outlined
                    label="ราคา"
                    placeholder="0.00"
                    :rules="[validators.numberValidator]"
                    type="number"              
                    hide-details="auto"          
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
                <v-text-field
                    v-model="filters.discountedPrice"
                    suffix="฿"
                    outlined
                    label="ราคาลด"
                    placeholder="0.00"
                    :rules="[validators.numberValidator]"
                    type="number"              
                    hide-details="auto"          
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
                <v-text-field
                    v-model="filters.defaultCost"
                    suffix="฿"
                    outlined
                    label="ต้นทุน"
                    placeholder="0.00"
                    :rules="[validators.numberValidator]"
                    type="number"              
                    hide-details="auto"          
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
                <v-text-field
                    v-model="filters.sku"                    
                    outlined
                    label="รหัสสินค้า"                                                            
                    hide-details="auto"          
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
                <v-text-field
                    v-model="filters.barCode"                    
                    outlined
                    label="บาร์โคด"                                                            
                    hide-details="auto"          
                    dense
                ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
                <v-btn color="primary" @click="applyFilter">Apply to All</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th
                                class="text-center"                            
                            >รูป</th>
                            <th
                                v-for="(attribute, index) in attributes"
                                :key="'header_' + index"
                                width="100px"
                                class="text-center"
                            >{{ attribute.name }}</th>
                            <th class="text-center">ราคา *</th>
                            <th class="text-center">ราคาลด</th>
                            <th class="text-center">ต้นทุน</th>
                            <th class="text-center">รหัสสินค้า</th>
                            <th class="text-center">barcode</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, valueIndex) in $attrs.value"
                        :key="'tr_' + valueIndex"
                      >
                        <td>
                          <VueFileAgent 
                            :uploadUrl="url + '/upload'" 
                            :uploadHeaders="{ 
                              Authorization: 'Bearer ' + $store.getters['auth/GET_ACCESS_TOKEN']
                            }"
                            :maxSize="'2MB'"
                            v-model="value.image"                                 
                            :multiple="false"
                            deletable
                            :meta="true"
                            :accept="'.jpg,.jpeg,.png'"
                            helpText="เลือกไฟล์ หรือ ลากไฟล์มาวางที่นี่"
                          ></VueFileAgent>
                        </td>
                        <template 
                          v-for="(optionValue, optionIndex) in value.options"                          
                        >
                          <td 
                            v-if="value.options.length == 1 
                            || (value.options.length > 1 && optionIndex != 0)
                            || (value.options.length > 1 && optionIndex == 0 && valueIndex % attributes[1].terms.length == 0)"
                            :rowspan="attributes.length > 1 && optionIndex == 0 ? attributes[1].terms.length : 1"
                          >{{ optionValue.term }}</td>
                        </template>
                        <td>
                          <v-text-field
                            v-model.number="value.defaultPrice"
                            outlined
                            label="*ราคาปกติ"
                            placeholder="0.00"            
                            :rules="[validators.required, validators.numberValidator]"
                            hide-details="auto"          
                            dense  
                            type="number"
                            suffix="฿"
                          ></v-text-field>                          
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="value.discountedPrice"
                            outlined
                            label="ราคาลด"
                            placeholder="0.00"            
                            :rules="[validators.numberValidator]"
                            hide-details="auto"          
                            dense  
                            type="number"
                            suffix="฿"
                          ></v-text-field>                          
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="value.defaultCost"
                            outlined
                            label="ต้นทุน"
                            placeholder="0.00"            
                            :rules="[validators.numberValidator]"
                            hide-details="auto"          
                            dense  
                            type="number"
                            suffix="฿"
                          ></v-text-field>                          
                        </td>                      
                        <td>
                          <v-text-field
                            v-model="value.sku"
                            outlined
                            label="รหัสสินค้า"                            
                            :rules="[validators.maxLengthValidator(value.sku, 50)]"                            
                            hide-details="auto"          
                            dense                            
                          ></v-text-field>                          
                        </td>
                        <td>
                          <v-text-field
                            v-model="value.barCode"
                            outlined
                            label="บาร์โคด"                            
                            :rules="[validators.maxLengthValidator(value.barCode, 50)]"
                            hide-details="auto"          
                            dense                              
                          ></v-text-field>                          
                        </td>
                      </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-row>
    </div>
</template>
<script>
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
export default {
  props: {
    attributes: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      default: 2048,
    },
    maxImages: Number,
    minWidth: Number,
    minHeight: Number,
    maxWidth: Number,
    maxHeight: Number,
    ratio: Number,
  },
  data() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      filters: {
        defaultPrice: null,
        discountedPrice: null,
        defaultCost: null,
        sku: null,
        barCode: null,
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  watch: {
    attributes: {
      handler(value, oldValue) {
        if (value.length != oldValue.length) this.$attrs.value = []
        this.formatData(value, oldValue)
      },
      deep: true,
    },
  },
  methods: {
    formatData(newData, oldData) {
      if (!!!this.$attrs.value) return

      const modelData = this.$attrs.value
      const newInputValue = []

      /*
      console.log(oldData)
      console.log(modelData)
      */

      if (newData.length > 1) {
        // 2 Attributes
        for (let i = 0; i < newData[0].terms.length; i++) {
          for (let j = 0; j < newData[1].terms.length; j++) {
            const attribute_0 = newData[0]
            const term_0 = attribute_0.terms[i]
            const attribute_1 = newData[1]
            const term_1 = attribute_1.terms[j]

            const index_0_FromOldData = !!oldData[0] ? oldData[0].terms.findIndex(data => data.name == term_0.name) : -1
            const index_1_FromOldData = !!oldData[1] ? oldData[1].terms.findIndex(data => data.name == term_1.name) : -1

            const searchIndex_0 = index_0_FromOldData < 0 ? i : index_0_FromOldData
            const searchIndex_1 = index_1_FromOldData < 0 ? j : index_1_FromOldData

            const findData = modelData.find(data => data.index[0] == searchIndex_0 && data.index[1] == searchIndex_1)

            if (!findData) {
              newInputValue.push({
                index: [i, j],
                options: [
                  {
                    attribute: attribute_0.name,
                    term: term_0.name,
                  },
                  {
                    attribute: attribute_1.name,
                    term: term_1.name,
                  },
                ],
                image: null,
                defaultPrice: null,
                discountedPrice: null,
                defaultCost: null,
                sku: null,
                barCode: null,
              })
            } else {
              newInputValue.push({
                ...findData,
                index: [i, j],
                options: [
                  {
                    attribute: attribute_0.name,
                    term: term_0.name,
                  },
                  {
                    attribute: attribute_1.name,
                    term: term_1.name,
                  },
                ],
              })
            }
          }
        }
      } else {
        // 1 Attribute
        for (let i = 0; i < newData[0].terms.length; i++) {
          const attribute = newData[0]
          const term = attribute.terms[i]

          const indexFromOldData = !!oldData[0] ? oldData[0].terms.findIndex(data => data.name == term.name) : -1

          const searchIndex = indexFromOldData < 0 ? i : indexFromOldData

          const findData = modelData.find(data => data.index[0] == searchIndex)

          if (!findData) {
            newInputValue.push({
              index: [i],
              options: [
                {
                  attribute: attribute.name,
                  term: term.name,
                },
              ],
              image: null,
              defaultPrice: null,
              discountedPrice: null,
              defaultCost: null,
              sku: null,
              barCode: null,
            })
          } else {
            newInputValue.push({
              ...findData,
              index: [i],
              options: [
                {
                  attribute: attribute.name,
                  term: term.name,
                },
              ],
            })
          }
        }
      }

      this.$emit('input', newInputValue)
    },
    applyFilter() {
      const { defaultPrice, discountedPrice, defaultCost, sku, barCode } = this.filters

      for (let i = 0; i < this.$attrs.value.length; i++) {
        const currentValue = this.$attrs.value[i]

        if (!!!currentValue.defaultPrice) currentValue.defaultPrice = defaultPrice
        if (!!!currentValue.discountedPrice) currentValue.discountedPrice = discountedPrice
        if (!!!currentValue.defaultCost) currentValue.defaultCost = defaultCost

        let suffix = ''
        for (let j = 0; j < currentValue.options.length; j++) {
          suffix += this.testEnglish(currentValue.options[j].term)
            ? currentValue.options[j].term[0].toUpperCase()
            : currentValue.options[j].term
        }

        if (!!!currentValue.sku && sku) currentValue.sku = sku + suffix
        if (!!!currentValue.barCode && barCode) currentValue.barCode = barCode + suffix
      }
    },
    testEnglish(text) {
      var english = /^[A-Za-z]*$/

      return english.test(text)
    },
  },
}
</script>