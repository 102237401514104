<template>
    <div>
      <v-row>
        <v-col>               
          <VueFileAgent 
            :uploadUrl="url + '/upload'" 
            :uploadHeaders="{ 
              Authorization: 'Bearer ' + $store.getters['auth/GET_ACCESS_TOKEN']
            }"
            :maxSize="'2MB'"
            v-model="$attrs.value.images"     
            :sortable="'handle'"       
            :multiple="true"
            deletable
            :meta="false"
            :accept="'.jpg,.jpeg,.png'"
            helpText="เลือกไฟล์ หรือ ลากไฟล์มาวางที่นี่"
          ></VueFileAgent>
        </v-col>
      </v-row>      
      <v-row>
        <v-col>
          <v-text-field
            v-model="$attrs.value.name"
            outlined
            label="*ชื่อสินค้า"            
            :rules="[validators.required, validators.maxLengthValidator($attrs.value.name, 250)]"
            hide-details="auto"          
            dense
            counter="250"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4" cols="12">
          <delay-autocomplete
            outlined
            hide-selected               
            deletable-chips
            multiple
            chips 
            cache-items
            v-model="$attrs.value.categories"             
            label="หมวดหมู่"
            placeholder="พิมพ์เพื่อค้นหาหมวดหมู่"
            no-data-text="ไม่พบข้อมูล"
            :url="'/product/categoryList'"
            dense            
            return-object
            ref="categoryAutocomplete"
            item-text="name"
            item-value="id"
          ></delay-autocomplete>
        </v-col>
        <v-col sm="4" cols="12">
          <delay-autocomplete
            outlined
            hide-selected                                       
            cache-items
            v-model="$attrs.value.brand"             
            label="แบรนด์"
            placeholder="พิมพ์เพื่อค้นหาแบรนด์"
            no-data-text="ไม่พบข้อมูล"
            :url="'/product/brandList'"
            dense
            :lazySearch="true"
            return-object
            ref="brandAutocomplete"
            item-text="name"
            item-value="id"
          ></delay-autocomplete>
        </v-col>
        <v-col sm="4" cols="12">
          <delay-autocomplete
            outlined
            hide-selected               
            deletable-chips
            multiple
            chips 
            cache-items
            v-model="$attrs.value.tags"             
            label="แท็ก"
            placeholder="พิมพ์เพื่อค้นหาแท็ก"
            no-data-text="ไม่พบข้อมูล"
            :url="'/product/tagList'"
            dense            
            return-object
            ref="tagAutocomplete"
            item-text="name"
            item-value="id"
          ></delay-autocomplete>
        </v-col>
      </v-row>
      <v-row
        no-gutters
      >
        <v-checkbox 
          v-model="$attrs.value.isVariable" 
          label="ใช้งานตัวเลือกสินค้า"
          :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
        ></v-checkbox>
      </v-row>      
      <!-- Variable Product Section -->
      <template v-if="$attrs.value.isVariable">
      <v-row>
        <v-col>
          <product-attribute-table v-model="$attrs.value.attributes" :mode="$store.getters['app/GET_APP_MODE']"></product-attribute-table>
        </v-col>        
      </v-row>
      <v-row>
        <v-col>
          <product-variation-table :attributes="$attrs.value.attributes" v-model="$attrs.value.variations"></product-variation-table>
        </v-col>
      </v-row>
      </template>
      <!-- Simple Product Section -->
      <template v-else>
      <v-row>
        <v-col sm="4" cols="12">
          <v-text-field
            v-model.number="$attrs.value.defaultPrice"
            outlined
            label="*ราคาปกติ"
            placeholder="0.00"            
            :rules="[validators.required, validators.numberValidator]"
            hide-details="auto"          
            dense  
            type="number"
          ></v-text-field>
        </v-col>
        <v-col sm="4" cols="12">
          <v-text-field
            v-model.number="$attrs.value.discountedPrice"
            outlined
            label="ราคาลด"
            placeholder="0.00"            
            :rules="[validators.numberValidator, validators.maxValueValidator($attrs.value.discountedPrice, $attrs.value.defaultPrice)]"
            hide-details="auto"          
            dense  
            type="number"
          ></v-text-field>
        </v-col>
        <v-col sm="4" cols="12">
          <v-text-field
            v-model.number="$attrs.value.defaultCost"
            outlined
            label="ต้นทุน"
            placeholder="0.00"            
            :rules="[validators.numberValidator]"
            hide-details="auto"          
            dense  
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12">
          <v-text-field
            v-model="$attrs.value.sku"
            outlined
            label="sku"
            :rules="[validators.maxLengthValidator($attrs.value.sku, 30)]"
            counter="30"
            hide-details="auto"          
            dense              
          ></v-text-field>
        </v-col>
        <v-col sm="6" cols="12">
          <v-text-field
            v-model="$attrs.value.barCode"
            outlined
            label="barcode"
            :rules="[validators.maxLengthValidator($attrs.value.barCode, 50)]"
            counter="50"
            hide-details="auto"          
            dense              
          ></v-text-field>
        </v-col>
      </v-row>
      </template>
    </div>            
</template>
<script>
import ProductAttributeTable from '@/components/ProductAttributeTable.vue'
import ProductVariationTable from '@/components/ProductVariationTable.vue'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
export default {
  data() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  methods: {},
  components: {
    ProductAttributeTable,
    ProductVariationTable,
  },
}
</script>