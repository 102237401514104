<template>
    <div>
        <h2><router-link :to="{ name: 'product' }">สินค้าทั้งหมด</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}สินค้า</h2>
        <v-card class="mt-4">
            <v-tabs
            v-model="selectedTab"
            >
            <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                    v-for="(tab, tab_index) in tabs"
                    :key="tab"
                >
                    <v-icon class="mr-2">{{ getIcon(tab_index) }}</v-icon> {{ tab }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="pa-8">
                <v-form v-model="isFormValid">
                  <v-tab-item
                      key="ข้อมูลสินค้า"
                  >
                      <basic-product-info 
                      v-model="formData"                       
                      />
                  </v-tab-item>

                  <v-tab-item
                      key="ขั้นสูง"
                  >
                      <advance-product-info v-model="formData" />
                  </v-tab-item>                
                </v-form>                
            </v-tabs-items>

             <v-card-actions class="justify-end">              
              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import {
  convertAttributesAndTermsFromServerToForm,
  convertDataFromServerToDelayAutoSelect,
  convertDelayAutoSelectValueToServer,
  convertFromServerToVueFileAgent,
  convertFromVueFileAgentToServer,
  convertInventoryDataFromServerToForm,
  convertVariationFromServerToForm,
} from '@/helpers/converter'
import { mdiArchiveOutline, mdiBookmarkOutline, mdiDotsHorizontal, mdiLockOpenOutline } from '@mdi/js'
import AdvanceProductInfo from './components/AdvanceProductInfo.vue'
import BasicProductInfo from './components/BasicProductInfo.vue'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      originalImages: [],
      formData: {
        /* BASIC PRODUCT PROPERTIES */
        images: [
          /*
          {
            name: 'asdsadas.png',
            ext: 'png',
            type: 'image',
            size: 1,
            url: 'https://aoodrive.sgp1.digitaloceanspaces.com/images/I8U9MV3GBCpWrAEVPBAElrsjclQ3ZYSD5egLg3LW.png',
            upload: {
              data: {
                id: 99,
                hash: 'eb2e16ce66c7407dcd79693a2c4a791b2cd3b224cdfb69ca03e6c34490bec468',
                lazada_id: null,
                shopee_id: null,
                URI: 'https://aoodrive.sgp1.digitaloceanspaces.com/images/I8U9MV3GBCpWrAEVPBAElrsjclQ3ZYSD5egLg3LW.png',
                created_at: '2022-08-23T22:57:10.000000Z',
                updated_at: '2022-08-23T22:57:10.000000Z',
                lastaccess_at: null,
                deleted_at: null,
              },
            },
          },
          */
        ],
        name: null,
        categories: [],
        brand: null,
        tags: [],
        isVariable: false,
        defaultCost: null,
        defaultPrice: null,
        discountedPrice: null,
        sku: null,
        barCode: null,
        attributes: [],
        variations: [],
        /* ADVANCE PRODUCT PROPERTIES */
        unit: null,
        package_weight: null,
        package_width: null,
        package_length: null,
        package_height: null,
        stock: false,
        is_vat: false,
        stockInfo: [],
      },
      selectedTab: null,
      tabs: ['ข้อมูลสินค้า', 'ขั้นสูง', 'shopee', 'lazada'],
      tabIcons: [mdiArchiveOutline, mdiDotsHorizontal, mdiLockOpenOutline, mdiBookmarkOutline],
    }
  },
  watch: {
    'formData.isVariable': function (value) {
      if (!value) this.formData.variations = []
    },
  },
  async created() {
    const product_id = this.$route.params.product_id

    if (product_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Product'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/product/' + product_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    getIcon(index) {
      return this.tabIcons[index]
    },
    async submitForm() {
      this.sendingData = true
      try {
        /*
        START OF HOTFIX SECTION

        Because VueFileAgent limitation when upload addition images for products, we need code in this section for preventing data loss
        */
        const originalImages = this.originalImages

        const formattedImages = this.formData.images.map(image => {
          if (!!image.upload.data) return image

          const found = originalImages.find(item => item.URI == image.urlResized)

          if (!found) return

          return {
            ...image,
            upload: {
              data: found,
            },
          }
        })
        /*
        END OF HOTFIX SECTION
        */

        const sendData = {
          ...this.formData,
          images: convertFromVueFileAgentToServer(formattedImages),
          tags: convertDelayAutoSelectValueToServer(this.formData.tags),
          categories: convertDelayAutoSelectValueToServer(this.formData.categories),
          brand_id: convertDelayAutoSelectValueToServer(this.formData.brand),
          variations: this.formData.variations.map(data => {
            return {
              ...data,
              image: convertFromVueFileAgentToServer(data.image),
            }
          }),
        }

        const product_id = this.$route.params.product_id
        if (product_id == 'create') await asyncPostAsJson('/product', sendData)
        else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/product/' + product_id, sendData)
        }

        this.$router.push({ name: 'product' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    convertDataFromServerToForm(data) {
      const attributes = convertAttributesAndTermsFromServerToForm(data.attributes, data.terms)
      const variations = convertVariationFromServerToForm(attributes, data.variations)
      const stockInfo =
        variations.length > 0
          ? convertInventoryDataFromServerToForm(variations)
          : data.inventory.map(item => {
              return {
                id: item.warehouse_id,
                quantity: item.quantity - item.reserved,
              }
            })
      this.formData = {
        name: data.name,
        images: convertFromServerToVueFileAgent(data.images),
        categories: convertDataFromServerToDelayAutoSelect(data.categories),
        brand: convertDataFromServerToDelayAutoSelect(data.brand),
        tags: convertDataFromServerToDelayAutoSelect(data.tags),
        isVariable: data.product_type == 'variable' ? true : false,
        defaultCost: data.defaultCost,
        defaultPrice: data.defaultPrice,
        discountedPrice: data.discountedPrice,
        sku: data.sku,
        barCode: data.barCode,
        attributes: attributes,
        variations: variations,
        /* ADVANCE PRODUCT PROPERTIES */
        unit: data.unit,
        package_weight: data.package_weight,
        package_width: data.package_width,
        package_length: data.package_length,
        package_height: data.package_height,
        stock: data.stock,
        is_vat: data.is_vat,
        stockInfo: stockInfo,
      }

      this.originalImages = data.images

      // console.log(this.formData)
    },
  },
  components: {
    BasicProductInfo,
    AdvanceProductInfo,
  },
}
</script>