<template>
    <div>
        <h1 class="text-center" v-if="warehouses.length == 0">ไม่พบคลังสินค้า โปรดเพิ่มคลังสินค้าก่อน</h1>
        <v-simple-table v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center" v-if="variations.length > 0">&lt;ตัวเลือกสินค้า&gt;</th>
                        <th 
                            v-for="(warehouse, warehouseIndex) in warehouses"
                            class="text-center"
                            :key="'warehouse_header_' + warehouseIndex"
                        >{{ warehouse.name }}</th>                            
                    </tr>
                </thead>
                <tbody v-if="variations.length > 0">
                  <tr
                    v-for="(value, valueIndex) in $attrs.value"
                    :key="valueIndex"
                  >
                    <td>
                      <template v-for="(option, optionIndex) in value.options">{{ optionIndex != 0 ? ', ' : '' }}{{ option.term }}</template>
                    </td>
                    <td
                      v-for="(warehouse, warehouseIndex) in value.warehouses"
                    >
                      <v-text-field
                        v-model.number="warehouse.quantity"
                        outlined
                        label="จำนวน"        
                        :rules="[validators.required, validators.integerValidator]"
                        hide-details="auto"          
                        dense  
                        type="number"
                        :disabled="disabled"
                      ></v-text-field>  
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      v-for="(warehouse, warehouseIndex) in $attrs.value"
                    >
                      <v-text-field
                        v-model.number="warehouse.quantity"
                        outlined
                        label="จำนวน"      
                        :rules="[validators.required, validators.integerValidator]"
                        hide-details="auto"          
                        dense  
                        type="number"
                        :disabled="disabled"
                      ></v-text-field>  
                    </td>
                  </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
import { integerValidator, required } from '@core/utils/validation'
export default {
  props: {
    variations: {
      type: Array,
      required: true,
      default: () => [],
    },
    warehouses: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validators: {
        required,
        integerValidator,
      },
    }
  },
  watch: {
    variations: {
      handler(value, oldValue) {
        if (value.length > 0) {
          if ((!!value[0].index && oldValue.length == 0) || value[0].index.length != oldValue[0].index.length)
            this.$attrs.value = []
          this.formatData()
        } else this.formatNoVariationData()
      },
      deep: true,
    },
    warehouses() {
      if (this.variations.length == 0) this.formatNoVariationData()
      else this.formatData(this.variations)
    },
  },
  created() {
    if (this.variations.length == 0) this.formatNoVariationData()
    else this.formatData(this.variations)
  },
  methods: {
    formatData() {
      if (!!!this.$attrs.value) return

      const stockData = this.$attrs.value
      const warehouses = this.warehouses

      const newInputValue = this.variations.map(currentVariation => {
        const findData = stockData.find(data => {
          return data.index.length === 1
            ? data.options[0].term === currentVariation.options[0].term
            : data.options[0].term === currentVariation.options[0].term &&
                data.options[1].term === currentVariation.options[1].term
        })

        if (!!findData) return findData

        return {
          index: currentVariation.index,
          options: currentVariation.options,
          warehouses: warehouses.map(warehouseData => {
            return {
              id: warehouseData.id,
              quantity: 0,
            }
          }),
        }
      })

      this.$emit('input', newInputValue)
      /*
      if (this.variations.length > 0) {
        
      } else {
        const newInputValue = warehouses.map(warehouseData => {
          const findData = stockData.find(data => warehouseData.id == data.id)

          if (findData) return findData

          return {
            id: warehouseData.id,
            quantity: 0,
          }
        })

        this.$emit('input', newInputValue)
      }
      */
    },
    formatNoVariationData() {
      if (this.warehouses.length == 0) return

      const tempValue = []
      const stockData = this.$attrs.value

      for (let j = 0; j < this.warehouses.length; j++) {
        const currentWarehouse = this.warehouses[j]

        const find = stockData.find(item => item.id == currentWarehouse.id)

        tempValue.push({
          id: currentWarehouse.id,
          quantity: !!find ? find.quantity : 0,
        })
      }

      this.$emit('input', tempValue)
    },
  },
}
</script>