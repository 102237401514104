<template>
    <div>
        <template v-for="(AttributeValue, index) in $attrs.value">
            <v-divider 
              v-if="index != 0" 
              class="my-4 mx-auto"
              inset
            ></v-divider>
            <v-row
                :key="'attribute_' + index"
            >
                <v-col cols="1" class="text-center">
                  <v-btn 
                    icon 
                    @click="removeAttribute(index)"
                    color="primary"
                    v-if="index != 0"
                    :disabled="mode != 'create'"
                  ><v-icon>{{ icons.mdiMinusBox }}</v-icon></v-btn>
                </v-col>                
                <v-col cols="11" sm="5">
                    <v-text-field
                        v-model="AttributeValue.name"
                        outlined
                        label="ชื่อตัวเลือก"
                        placeholder="เช่น สี ไซส์ ขนาด"
                        :rules="[validators.maxLengthValidator(AttributeValue.name, 50)]"
                        counter="50"
                        hide-details="auto"          
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5">
                    <v-text-field
                        v-model="AttributeValue.terms[0].name"
                        outlined
                        label="ข้อมูลตัวเลือก"
                        placeholder="เช่น เล็ก ใหญ่ แดง ดำ"
                        :rules="[validators.maxLengthValidator(AttributeValue.terms[0].name, 50)]"
                        counter="50"
                        hide-details="auto"          
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <template v-if="AttributeValue.terms.length > 1">
            <v-row 
                v-for="(termValue, termIndex) in AttributeValue.terms.slice(1)"
                :key="'attribute_' + index + '_term_' + (termIndex + 1)"
            >
                <v-col cols="12" sm="6"></v-col>
                <v-col cols="10" sm="5">
                    <v-text-field
                        v-model="termValue.name"
                        outlined
                        label="ข้อมูลตัวเลือก"
                        placeholder="เช่น เล็ก ใหญ่ แดง ดำ"
                        :rules="[validators.maxLengthValidator(termValue.name, 50)]"
                        counter="50"
                        hide-details="auto"          
                        dense
                    ></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <v-btn 
                    icon 
                    @click="removeTerm(index, termIndex + 1)"
                  >
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>                  
                </v-col>
            </v-row>
            </template>
            <v-row>
              <v-col cols="12" sm="6"></v-col>
              <v-col cols="10" sm="5">
                <v-btn 
                  color="primary" 
                  outlined 
                  @click="addTerm(index)"
                  block
                ><v-icon class="mr-2">{{ icons.mdiPlus }}</v-icon>เพิ่มข้อมูลตัวเลือก</v-btn>
              </v-col>
            </v-row>
        </template>
        <v-row v-if="mode == 'create' && !!$attrs.value && $attrs.value.length < 2">
          <v-col>
            <v-btn
              color="primary"
              block
              @click="addAttribute"
            >
              <v-icon class="mr-2">{{ icons.mdiPlus }}</v-icon>เพิ่มตัวเลือก
            </v-btn>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { requiredIf, maxLengthValidator } from '@core/utils/validation'
import { mdiPlus, mdiTrashCanOutline, mdiArrowUp, mdiArrowDown, mdiMinusBox } from '@mdi/js'
export default {
  props: {
    mode: {
      default: 'create',
      type: String,
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiArrowUp,
        mdiArrowDown,
        mdiMinusBox,
      },
      validators: {
        requiredIf,
        maxLengthValidator,
      },
    }
  },
  created() {
    if (!!this.$attrs.value && Array.isArray(this.$attrs.value) && this.$attrs.value.length == 0) {
      this.$emit('input', [
        {
          id: null,
          name: '',
          terms: [
            {
              id: null,
              name: '',
            },
          ],
        },
      ])
    }
  },
  methods: {
    addAttribute() {
      const newData = JSON.parse(JSON.stringify(this.$attrs.value))

      newData.push({
        id: null,
        name: '',
        terms: [
          {
            id: null,
            name: '',
          },
        ],
      })

      this.$emit('input', newData)
    },
    removeAttribute(attributeIndex) {
      const newData = JSON.parse(JSON.stringify(this.$attrs.value))

      newData.splice(attributeIndex, 1)

      this.$emit('input', newData)
    },
    addTerm(attributeIndex) {
      const newData = JSON.parse(JSON.stringify(this.$attrs.value))

      newData[attributeIndex].terms.push({
        id: null,
        name: '',
      })

      this.$emit('input', newData)
    },
    removeTerm(attributeIndex, termIndex) {
      const newData = JSON.parse(JSON.stringify(this.$attrs.value))

      newData[attributeIndex].terms.splice(termIndex, 1)

      this.$emit('input', newData)
    },
  },
}
</script>