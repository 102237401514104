<template>
    <div>
        <v-row>
            <v-col sm="6" cols="12">
              <v-text-field
                  v-model.number="$attrs.value.package_weight"
                  outlined
                  label="น้ำหนัก"            
                  placeholder="หน่วย kg (รวมน้ำหนักของบรรจุภัณฑ์ด้วย)"
                  :rules="[validators.numberValidator]"
                  hide-details="auto"          
                  dense                    
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col sm="6" cols="12">
              <v-text-field
                  v-model.number="$attrs.value.unit"
                  outlined
                  label="หน่วยนับ"            
                  placeholder="เช่น ชิ้น ลัง กรัม"
                  :rules="[validators.maxLengthValidator($attrs.value.unit, 10)]"
                  hide-details="auto"          
                  dense                    
                  counter="10"
              ></v-text-field>
            </v-col>
        </v-row>      
        <v-row>
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model.number="$attrs.value.package_width"
                    outlined
                    label="กว้าง"            
                    placeholder="หน่วย cm"
                    :rules="[validators.numberValidator]"
                    hide-details="auto"          
                    dense                    
                    type="number"
                ></v-text-field>
            </v-col>
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model.number="$attrs.value.package_length"
                    outlined
                    label="ยาว"            
                    placeholder="หน่วย cm"
                    :rules="[validators.numberValidator]"
                    hide-details="auto"          
                    dense                    
                    type="number"
                ></v-text-field>
            </v-col>
            <v-col sm="4" cols="12">
                <v-text-field
                    v-model.number="$attrs.value.package_height"
                    outlined
                    label="สูง"            
                    placeholder="หน่วย cm"
                    :rules="[validators.numberValidator]"
                    hide-details="auto"          
                    dense                    
                    type="number"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="$attrs.value.stock"
              label="ใช้ระบบสต็อค"
              color="primary"              
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="$attrs.value.stock">
          <v-col>
            <stock-per-warehouses
              :variations="$attrs.value.variations"
              :warehouses="warehouses"
              v-model="$attrs.value.stockInfo"
              :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="$attrs.value.is_vat"
              label="เป็นสินค้าที่มีภาษีมูลค่าเพิ่ม (มี VAT)"
              color="primary"              
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import {
  required,
  lengthValidator,
  minLengthValidator,
  maxLengthValidator,
  integerValidator,
  numberValidator,
  minValueValidator,
  maxValueValidator,
} from '@core/utils/validation'
import { asyncGet } from '@/helpers/asyncAxios'
import StockPerWarehouses from '@/components/StockPerWarehouses.vue'
export default {
  data() {
    return {
      warehouses: [],
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  created() {
    let that = this
    asyncGet('/product/warehouseList').then(({ warehouses }) => {
      that.warehouses = warehouses
    })
  },
  components: {
    StockPerWarehouses,
  },
}
</script>